<template>

  <div class="wrapper">
    <BottomNavigation></BottomNavigation>
  </div>

</template>

<script>
const BottomNavigation = () => import("./ShareBottomNavigation.vue");
import bettingserve from "@/services/bettingserve";

export default {
  name: "share",
  components: {
    BottomNavigation,
  },
  data: function () {
    return {
      highlights: true,
      upcoming: false,
      today: false,
      sub_page: "",
      mqttClient: "",
      bookingCode: this.$route.params.code,
    };
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "share");
    this.$store.dispatch("setHour", -1);
    this.$store.dispatch("setCompetitionID", 0);
    this.$store.dispatch("setShareCode", this.bookingCode);

    console.log("Booking Code on Mount:", this.bookingCode);

    this.reloadProfile();

    this.clearBetSlip();
    this.getPicks();

    // Log the values to check if they are set correctly
    console.log("Booking Code after Mount:", this.bookingCode);
    console.log("Share Code from Vuex:", this.share_code);

    // Check if share_code from Vuex is set
    if (this.share_code && this.share_code.length > 0) {
      this.setValue("utm_source", this.share_code);
      this.setValue("utm_campaign", "share_bet");
    }

    var vm = this;

    this.EventBus.$on("share:code", function (code) {
      vm.bookingCode = code;

      // Update Vuex store with the new booking code
      vm.$store.dispatch("setShareCode", code);

      vm.clearBetSlip();
      vm.getPicks();
    });
  },

  computed: {
    betslip_count: function () {
      return this.$store.state.betslip.total;
    },
    current_sub_page: function () {
      return this.$store.state.current_sub_page;
    },
    sport_id: function () {
      return this.$store.state.sport_id;
    },
    share_code() {
      return this.$store.getters.shareCode;
    },
  },
  methods: {

    getHomeCompetitorName: function (matchName) {

      if (matchName === undefined) {

        return "";

      }

      return matchName.split("vs.")[0];

    },

    getAwayCompetitorName: function (matchName) {
      if (matchName === undefined) {
        return "";
      }
      return matchName.split("vs.")[1];
    },

    getPicks: function () {
      var vm = this;
      var path = process.env.VUE_APP_BASE_BETTING_URL + "/bookings/" + this.bookingCode;
      bettingserve
        .get(path)
        .then((res) => {

          var results = res.data;

          console.log(JSON.stringify(results, undefined, 2));

          if (results.length === 0) {
            this.$toast.open({
              message: 'You have entered invalid or expired booking code',
              type: 'error',
              position: 'top'
            });
          } else {
            vm.jQuery.each(results, function (k, v) {
              var sport_id = v.sport_id;
              var match_id = v.match_id;
              var market_name = v.market_name;
              var market_id = v.market_id;
              var specifier = v.specifier;
              var producer_id = v.producer_id;
              var away_team = vm.getAwayCompetitorName(v.event);
              var home_team = vm.getHomeCompetitorName(v.event);

              vm.addPick(
                sport_id,
                match_id,
                market_name,
                market_id,
                home_team,
                away_team,
                producer_id,
                specifier,
                {
                  producer_id: v.producer_id,
                  producer_status: v.producer_status,
                  status: v.status,
                  active: v.active,
                  odds: v.odds,
                  outcome_id: v.outcome_id,
                }
              );
            });

            console.log("I am HERE");
            vm.EventBus.$emit("event:betslip:show");
            vm.setValue("booking_code", vm.bookingCode);
            vm.setValue("stake", 50);
          }
        })
        .catch((err) => {
          if (err.response) {
            this.$toast.open({
              message: err.response.data.error_message,
              type: 'error',
              position: 'top'
            });
          }
        });
    },

    setUpcoming: function () {
      this.highlights = false;
      this.today = false;
      this.upcoming = true;
    },
    setToday: function () {
      this.highlights = false;
      this.today = true;
      this.upcoming = false;
    },
    setHighlight: function () {
      this.highlights = true;
      this.today = false;
      this.upcoming = false;
    },
    setSubPage: function (page) {
      this.$store.dispatch("setCurrentSubPage", page);
      this.sub_page = page;
    },
    isHighlight: function () {
      return this.highlights;
    },
    isToday: function () {
      return this.today;
    },
    isUpcoming: function () {
      return this.upcoming;
    },

    geSubPageActiveClass: function (subpage) {
      return subpage === this.sub_page ? "active" : "";
    },
    setSport: function (sportID) {
      this.$store.dispatch("setSportID", sportID);
    },
  },
};
</script>
